































































import { Component, Vue } from 'vue-property-decorator'
import { StateAbbreviationsEnum, TimezonesEnum, UserCustomerNavsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '../../../stores/appStore'

@Component
export default class Profile extends Vue {
  private firstName = AuthState.user.firstName
  private lastName = AuthState.user.lastName
  private email = AuthState.user.emailAddress
  private timezone = AuthState.user.timezone
  private customerNavType = AuthState.user.preferences.customer.nav

  private timezones = mapObjectVuetifySelect(TimezonesEnum)
  private customerNavs = mapObjectVuetifySelect(UserCustomerNavsEnum)
  private navOptions = UserCustomerNavsEnum

  public updatePref (preferenceName: string, value: any) {
    AuthState.UpdatePreference({ preferenceName, value })
  }

  public handleSubmit (): void {
    console.log('Saving Preferences...')
  }

  public handleReset (): void {

  }
}
